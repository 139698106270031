import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import styles from "./InputSection.module.scss";

type Props = {
  label: string;
  placeholder: string;
  inputId: string;
  inputType: React.HTMLInputTypeAttribute;
  name: string;
  required?: boolean;
  register: UseFormRegister<any>; // TODO : Remove 'any' type
  formErrors: DeepMap<any, FieldError>;
};
function InputSection(props: Props) {
  const {
    label,
    placeholder,
    inputId,
    inputType,
    name,
    register,
    formErrors,
  } = props;

  
  const errorName = formErrors?.[name];
  

  return (
    <section className={styles.inputSection}>
      <label className={styles.inputSection__titleLabel} htmlFor={inputId}>
        {label}
      </label>
      <input
        type={inputType}
        id={inputId}
        placeholder={placeholder}
        {...register(name)}
        has-error={errorName ? "yes" : "no"} // check the style sheet
      />
      <label className={styles.inputSection__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message}
      </label>
    </section>
  );
}
export default InputSection;
