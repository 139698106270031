import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // I removed strict mode because this would rerender the app twice on dev mode
  // further readings
  // https://reactjs.org/docs/strict-mode.html
  // https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects

  //  <React.StrictMode>

  <BrowserRouter>
    <App />
  </BrowserRouter>
  //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
