import axiosClient from "../libs/axios";


async function getAccessToken(email : string){
  return axiosClient.get(`/users/${email}/token`)
}
async function getEtablissementByOID(oid: string) {
  return axiosClient.get(`/users/etablissement/${oid}`);
}

const usersServices = {
  getEtablissementByOID,
  getAccessToken
};

export default usersServices;
