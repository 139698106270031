import { showNotification } from "@mantine/notifications";
import { AccountInfo } from "@azure/msal-browser";

  // * mantine notification system

export function showNotif(message:string) {
    showNotification({
      // title: "Default notification",
      message: message,
      // icon: <Fragment>{successIcon}</Fragment>,
      styles: (theme) => ({
        root: {
          backgroundColor: "#91BE1E",
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          "&:hover": { backgroundColor: "#91BE1E" },
        },
      }),
    });
  }

  export function getUserInfo(accounts :AccountInfo[]){
    const userEmail = accounts[0].idTokenClaims?.email as string;
    const userName = accounts[0].name || "prenom nom";
    const names = userName.split(" ");
    const prenom = names[0];
    const nom = names[1];

    return {nom,prenom,userEmail}
  }