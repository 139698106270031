import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { AxiosResponse } from "axios";

export function getUserEmailAndOID(accounts :AccountInfo[]){
  const oid: string = accounts[0].idTokenClaims?.oid as string;
  const userEmail = accounts[0].idTokenClaims?.email;

return {userEmail,oid}
}
export async function getToken(
  userEmail: string,
  msalInstance: PublicClientApplication,
  getAccessToken: (email: string) => Promise<AxiosResponse<any, any>>
) {

  const alertMessage = "Oups ! Vous n'avez pas les droits nécessaires pour accéder à la plateforme, merci d'envoyer un email à l'adresse vismavie@clariane.com afin que l'administrateur puisse débloquer rapidement la situation."
  try {
    const tokenResponse = await getAccessToken(userEmail as string);
    const token: string = tokenResponse.data;
    if (!token) {
      alert(alertMessage);
      await msalInstance.logoutRedirect();
    }

    return token;
  } catch (error) {
    alert(alertMessage);
    await msalInstance.logoutRedirect();
  }
}



export function scrollToAnchor(anchor: HTMLElement | null) {
  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth" });
  }
}
