import { Modal } from "@mantine/core";
import { closeIcon } from "../../../assets/svgs/icons/icons.svg";
import Button from "../Button/Button";
import styles from "./ActionPopup.module.scss";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  textArray: string[];
  type: "confirm" | "cancel";
  cancelButtonText: string;
  confirmButtonText: string;
  onConfirm: () => void;
};
export default function ActionPopup(props: Props) {
  const {
    isOpen = false,
    setIsOpen,
    title,
    textArray,
    type,
    cancelButtonText,
    confirmButtonText,
    onConfirm,
  } = props;

  const cancelColor = "#E13B17";
  const confirmColor = "#00AEC3";

  function onClickClose() {
    setIsOpen(false);
  }
  return (
    <Modal
      opened={isOpen}
      onClose={onClickClose}
      centered
      withCloseButton={false}
      size="auto"
    >
      <article className={styles.actionPopup}>
        <header className={styles.actionPopup__header}>
          <h1
            style={{ color: type === "confirm" ? confirmColor : cancelColor }}
          >
            {title}
          </h1>
          <button type="button" onClick={onClickClose}>
            {closeIcon}
          </button>
        </header>
        <main className={styles.actionPopup__main}>
          {textArray.map((text: string, index: number) => {
            return <p key={index}>{text}</p>;
          })}
        </main>
        <footer className={styles.actionPopup__footer}>
          <Button
            text={cancelButtonText}
            textColor="dark"
            buttonBackGroundColor="white"
            onClick={onClickClose}
          />
          <Button
            text={confirmButtonText}
            textColor="white"
            buttonBackGroundColor={type === "confirm" ? "blue" : "red"}
            onClick={onConfirm}
          />
        </footer>
      </article>
    </Modal>
  );
}
