import { useEffect } from "react";

import { IEtablissement } from "../../../schemas-and-types/etablissement/etablissemen.schema";
import EtablissementConfirmationPageContainer from "../../../components/etablissement/recapitulatif/EtablissementRecapPageContainer/EtablissementConfirmationPageContainer";
import { useEtablissementStore } from "../../../stores/etablissement/etablissement.store";
import { usePagesToDisplayStore } from "../../../stores/pages-to-display/pages-to-display.store";
import { scrollToAnchor } from "../../../utils";

//  TODO clean up the logic section of this componenet
export default function EtablissementConfirmationPage() {
  const etablissement = useEtablissementStore((state) => state.etablissement);
  // I wrapped the confirmation page in a container in order to wait
  // for react-query to finish fetching the etablissement in order to render the page

  const setPagesToDisplay = usePagesToDisplayStore(
    (state) => state.setPagesToDisplay
  );
  useEffect(() => {
    setPagesToDisplay([]);
    scrollToAnchor(document.getElementById("demande-envoyee-anchor"));
  }, [setPagesToDisplay]);
  return (
    <EtablissementConfirmationPageContainer
      etablissement={etablissement as IEtablissement}
    ></EtablissementConfirmationPageContainer>
  );
}
