import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FooterBackGroundImage from "../../../../assets/png/error-page-decoration.png";
import { telechargerBlueIcon } from "../../../../assets/svgs/icons/icons.svg";
import { generateInscriptionModificationEmail } from "../../../../hooks/react-query/etablissement/email-formats";
import {
  useDeleteEtablissement,
  useUpdateEtablissement,
} from "../../../../hooks/react-query/etablissement/etablissement.queries";
import { getInitialValuesOfEtablissement } from "../../../../pages/etablissement/confirmation/utils";
import {
  Etablissement,
  EtablissementValidationSchema,
  EtablissementValidationType,
  IEtablissement,
  Localisation,
  Metier,
  TypeEtablissement,
} from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import mailingServiceService from "../../../../services/mailing-service/mailing-service.service";
import ActionPopup from "../../../global/ActionPopup/ActionPopup";
import Button from "../../../global/Button/Button";
import Loader from "../../../global/Loader/Loader";
import PossiblitesAccueils from "../../InscriptionEtablissement/PossiblitesAccueils/PossiblitesAccueils";
import VMVDescription from "../../VMVDescription/VMVDescription";
import VMVValeurs from "../../VMVValeurs/VMVValeurs";
import InformationEtabModification from "../../modification-flow/InformationEtabModification/InformationEtabModification";
import InformationEtabRecap from "../InformationEtabRecap/InformationEtabRecap";
import PossibilitesAccueilsEtabRecap from "../PossibilitesAccueilsEtabRecap/PossibilitesAccueilsEtabRecap";
// import styles from "./EtablissementConfirmationPageContainer.module.scss";
import { useNavigate } from "react-router-dom";
import { useGetRegions } from "../../../../hooks/react-query/dropdowns/regions.queries";
import { useGetTypeEtablissement } from "../../../../hooks/react-query/dropdowns/type-etablissement.queries";
import { getUserInfo } from "../../../../hooks/react-query/etablissement/utils";
import { scrollToAnchor } from "../../../../utils";
import ChangeEtablissementTypePopup from "../../../global/ChangeEtablissementTypePopup/ChangeEtablissementTypePopup";
import { executeAddressNotValideSideEffect } from "../../InscriptionEtablissement/utils";
import styles from "./styles.module.scss";
import { formatEtablissementToUpdateUI } from "./utils";
type Props = {
  etablissement: Etablissement;
};
export default function EtablissementConfirmationPageContainer(props: Props) {
  const { etablissement } = props;
  const navigate = useNavigate();

  // * react-to-print
  const componentToPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  //* msal
  const { accounts } = useMsal();
  const oid = accounts[0].idTokenClaims?.oid as string;

  //* useState
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isChangeEtabPopupOpen, setChangeEtabIsPopupOpen] =
    useState<boolean>(false);
  const [metiers, setMetiers] = useState<Metier[]>(
    etablissement?.metiers as Metier[]
  );


  const [localisationOption, setLocalisationOption] = useState<Localisation>(
    // etablissement.localisation ? etablissement.localisation : { address: "", region: "", countrySecondarySubdivision: "", latitude: 0, longitude: 0 }
    etablissement.localisation as Localisation

  );

  // UI
  // State concerning which UI to show
  // Either the 'modification' componenets or the one for displaying infos
  const [isInfoEtabModification, setIsInfoEtabModification] =
    useState<boolean>(false);
  const [
    isPossibiliteAccueilModification,
    setIsPossibiliteAccueilModification,
  ] = useState<boolean>(false);

  // * react-hook-form
  const {
    register,
    handleSubmit,
    formState,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm<EtablissementValidationType>({
    resolver: yupResolver(EtablissementValidationSchema),
    values: {
      ...getInitialValuesOfEtablissement(etablissement as IEtablissement),
      address: etablissement?.localisation?.address ?? "-",
    },
  });
  const watchTypeEtablissement = watch("type");
  //* react-query
  const { mutate: updateEtablisseement } = useUpdateEtablissement(
    watchTypeEtablissement as TypeEtablissement,
    setChangeEtabIsPopupOpen,
    setIsLoading,
    setIsInfoEtabModification,
    setIsPossibiliteAccueilModification
  );
  const onSubmit = (data: EtablissementValidationType) => {
    if (localisationOption.address === "") {
      executeAddressNotValideSideEffect(setError);
      return;
    }
    // the onSubmit function will automaticaly get executed when
    // a button of type "submit" would get clicked
    // It means that you won't need to explicitly pass this function
    // on the 'onClick' listener of the button

    setIsLoading(true);
    const updateObject = formatEtablissementToUpdateUI(
      data,
      localisationOption,
      oid,
      metiers
    );

    // * User info for Mailing
    const { nom, prenom, userEmail } = getUserInfo(accounts);
    // I'm sending the update email here because
    // It wouldn't work on the onSuccess callback of the
    // useMutation function of the useUpdateEtablissement hook
    console.log("updateObject", updateObject);
    new Promise(async (resolve, reject) => {
      try {
        updateEtablisseement({
          id: etablissement._id as string,
          requestBody: updateObject,
        });
        resolve(updateObject);
      } catch (error) {
        reject(error);
      }
    }).then(async (data) => {
      const updatedEtablissement: IEtablissement = data as IEtablissement;
      await mailingServiceService.sendEmail({
        email: userEmail,
        subject: "Vos modifications d'inscription à l'évènement Vis ma Vie.",
        body: generateInscriptionModificationEmail(
          prenom,
          nom,
          updatedEtablissement
        ),
      });
    });
  };
  const formErrors = formState.errors;

  useEffect(() => {
    if (watchTypeEtablissement !== "EHPAD") {
      // removeAnimationBlock(
      //   setMetiers,
      //   disponiblitesPremiereSemaine,
      //   disponiblitesDeuxiemeSemaine
      // );
    }
    // else{
    //   {
    //     if (!metiers.some((metier) => metier.title === "Animation"))
    //       setMetiers((current) => [
    //         ...current,
    //         {
    //           title: "Animation",
    //           job_title: "Animateur(trice)",
    //           premiere_semaine: disponiblitesPremiereSemaine,
    //           deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    //           isDisponible: true,
    //         },
    //       ]);
    //   }
    // }

    scrollToAnchor(document.getElementById("confirmation-page-anchor"));
  }, []);

  //* Delete etablissement
  function onClickAnnuler() {
    setIsPopupOpen(true);
  }

  const { mutate: deleteEtablissement } = useDeleteEtablissement(setIsLoading);
  function onConfirmDelete() {
    setIsLoading(true);
    deleteEtablissement(etablissement._id as string);
    setLocalisationOption({ address: "", region: "", countrySecondarySubdivision: "", latitude: 0, longitude: 0 });
    // navigate(0)
  }

  const {
    data: typeEtablissementsOptions,
    isLoading: areTypeEtabOptionsLoading,
  } = useGetTypeEtablissement();

  const { data: regionsOptions, isLoading: areRegionOptionsLoading } =
    useGetRegions();

  if (isLoading) return <Loader />;
  return (
    <article
      id="confirmation-page-anchor"
      className={styles.etablissementConfirmationPage}
    >
      <header className={styles.etablissementConfirmationPage__header}>
        <h1>Vis ma vie</h1>
        <h2>Votre inscription</h2>
      </header>
      <main className={styles.etablissementConfirmationPage__main}>
        {/* Informations établissement */}

        {/*  Here we render either the InformationsEtab component which holds inputs that can be modified,*/}
        {/* or the one which only displays data */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <section
            className={
              styles.etablissementConfirmationPage__main__buttonSection
            }
          >
            <Button
              buttonBackGroundColor="blue"
              type="button"
              text="Télécharger"
              textColor="white"
              icon={telechargerBlueIcon}
              onClick={handlePrint}
            />
            <Button
              buttonBackGroundColor="red"
              type="button"
              textColor="white"
              text="Annuler votre participation"
              onClick={onClickAnnuler}
            />
          </section>
          <section ref={componentToPrintRef}>
            {isInfoEtabModification ? (
              <InformationEtabModification
                register={register}
                formErrors={formErrors}
                setError={setError}
                clearErrors={clearErrors}
                setIsInfoEtabModification={setIsInfoEtabModification}
                setRegisterValue={setValue}
                localisationOption={localisationOption}
                setLocalisationOption={setLocalisationOption}
                typeEtablissementsOptions={typeEtablissementsOptions}
                areTypeEtabOptionsLoading={areTypeEtabOptionsLoading}
                regionsOptions={regionsOptions}
                areRegionOptionsLoading={areRegionOptionsLoading}
              />
            ) : (
              <InformationEtabRecap
                canUpdate={true}
                setIsInfoEtabModification={setIsInfoEtabModification}
                etablissement={etablissement as IEtablissement}
              />
            )}

            {/* Possibilité(s) d’accueil(s) */}

            {/*  Here we render either the ossiblitesAccueils component which holds inputs that can be modified, */}
            {/* or the one which only displays data */}
            {isPossibiliteAccueilModification ? (
              <PossiblitesAccueils
                setIsPossibiliteAccueilModification={
                  setIsPossibiliteAccueilModification
                }
                metiers={metiers}
                setMetiers={setMetiers}
                typeEtablissement={watchTypeEtablissement as TypeEtablissement}
                onClickEnregister={() => { }}
              />
            ) : (
              <PossibilitesAccueilsEtabRecap
                canUpdate={true}
                setIsPossibiliteAccueilModification={
                  setIsPossibiliteAccueilModification
                }
                metiers={metiers}
                typeEtablissement={watchTypeEtablissement as TypeEtablissement}
              />
            )}
          </section>

          <section
            className={
              styles.etablissementConfirmationPage__main__buttonSection__secondary
            }
          >
            <Button
              buttonBackGroundColor="blue"
              text="Télécharger"
              textColor="white"
              icon={telechargerBlueIcon}
              onClick={handlePrint}
            />
            <Button
              buttonBackGroundColor="red"
              textColor="white"
              text="Annuler votre participation"
              onClick={onClickAnnuler}
            />
          </section>
        </form>
      </main>
      <VMVDescription />
      <VMVValeurs />
      <footer
        style={{ backgroundImage: `url(${FooterBackGroundImage})` }}
      ></footer>
      <ActionPopup
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        title="Annuler votre participation"
        textArray={[
          "Vous êtes sur le point d'annuler votre inscription.",
          "Cette action supprimera l'ensemble des données et est irréversible.",
          "",
          `Vous avez jusqu'au ${process.env.REACT_APP_DATE_END_INSCRIPTION_LITERAL} pour vous réinscrire.`,
          "",
          "Voulez-vous annuler votre participation ?",
        ]}
        type="cancel"
        cancelButtonText="Annuler"
        confirmButtonText="Confirmation de l’annulation"
        onConfirm={onConfirmDelete}
      />
      <ChangeEtablissementTypePopup
        isOpen={isChangeEtabPopupOpen}
        setIsOpen={setChangeEtabIsPopupOpen}
      />
    </article>
  );
}
