import {
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import InputSection from "../../../global/InputSection/InputSection";
import TextAreaSection from "../../../global/TextAreaSection/TextAreaSection";
import Button from "../../../global/Button/Button";
import { whiteCheckIcon } from "../../../../assets/svgs/icons/icons.svg";

import {
  EtablissementValidationType,
  Localisation,
  TypeEtablissement,
} from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import DropdownSection from "../../../global/DropdownSection/DropdownSection";

import PhoneSection from "../../../global/PhoneSection/PhoneSection";
import MapsSearchSection from "../../../global/MapsSearchSection/MapsSearchSection";

import styles from "./InformationEtabModification.module.scss";
import { useNavigate } from "react-router-dom";
import { useGetRegions } from "../../../../hooks/react-query/dropdowns/regions.queries";
import { useGetTypeEtablissement } from "../../../../hooks/react-query/dropdowns/type-etablissement.queries";

type Props = {
  // react-hook-form
  register: UseFormRegister<EtablissementValidationType>;
  formErrors: FieldErrors<EtablissementValidationType>;
  setIsInfoEtabModification: React.Dispatch<React.SetStateAction<boolean>>;
  setError: UseFormSetError<EtablissementValidationType>;
  clearErrors: UseFormClearErrors<any>;

  setRegisterValue: UseFormSetValue<any>; // TODO : Remove 'any' type

  // related to dropdowns
  typeEtablissementsOptions: any; // TODO remove any type
  areTypeEtabOptionsLoading: boolean;
  regionsOptions: any; // TODO remove any type
  areRegionOptionsLoading: boolean;
  // set type etablissement to set the metier list to display (with or without 'Animation' section)

  // localisation
  setLocalisationOption: React.Dispatch<React.SetStateAction<Localisation>>;
  localisationOption: Localisation;

  onClickEnregister?: () => void;
};
export default function InformationEtabModification(props: Props) {
  const {
    register,
    formErrors,
    setError,
    clearErrors,
    setRegisterValue,

    setIsInfoEtabModification,
    typeEtablissementsOptions,
    areTypeEtabOptionsLoading,
    regionsOptions,
    areRegionOptionsLoading,

    setLocalisationOption,
    localisationOption,
    onClickEnregister,
  } = props;

  const navigate = useNavigate();
  function onClickAnnulerModification() {
    setIsInfoEtabModification(false);
    navigate(0);
  }

  return (
    <article className={styles.informationEtabModification}>
      <header className={styles.informationEtabModification__header}>
        <h2>Informations établissement *</h2>
      </header>
      <main className={styles.informationEtabModification__main}>
        <article className={styles.informationEtabModification__top}>
          <section className={styles.informationEtabModification__top__left}>
            <section>
              <DropdownSection
                options={typeEtablissementsOptions?.data?.list || []}
                label="Type d'établissement"
                inputId="inscriptionEtab_infoEtab_type"
                placeholder="Sélectionner un type d'établissement"
                name="type"
                register={register}
                formErrors={formErrors}
                areOptionsLoading={areTypeEtabOptionsLoading}
              />
            </section>
            <section>
              <InputSection
                label="Nom de l'établissement"
                inputId="modificationEtab_nom"
                inputType="text"
                placeholder="Nom de l'établissement"
                name="name"
                required
                register={register}
                formErrors={formErrors}
              />
              <MapsSearchSection
                label="Adresse"
                inputId="inscriptionEtab_infoEtab_adresse"
                placeholder="Adresse de l'établissement"
                name="address"
                register={register}
                formErrors={formErrors}
                setError={setError}
                clearErrors={clearErrors}
                localisationOption={localisationOption}
                setLocalisationOption={setLocalisationOption}
                setRegisterValue={setRegisterValue}
              />
            </section>
            <section>
              <TextAreaSection
                label="Accès établissement et transports"
                textAreaId="modificationEtab_access"
                placeholder="Préciser l’accessibilité à votre établissement (axe routier, gare, station de métro, tramway, code portail,…)"
                name="information_access"
                required
                register={register}
                formErrors={formErrors}
              />
            </section>
          </section>
          <section
            className={styles.informationEtabModification__top__separationLine}
          ></section>
          <section className={styles.informationEtabModification__top__right}>
            <section>
              <DropdownSection
                options={regionsOptions?.data?.list || []}
                label="Région opérationnelle de rattachement de l'établissement"
                inputId="inscriptionEtab_region"
                placeholder="Sélectionner une région opérationnelle"
                name="region"
                register={register}
                formErrors={formErrors}
                areOptionsLoading={areRegionOptionsLoading}
              />
            </section>
            <section>
              <InputSection
                label="NOM du ou de la DR"
                inputId="modificationEtab_nomDR"
                inputType="text"
                placeholder="NOM du ou de la DR"
                name="nom_dr"
                required
                register={register}
                formErrors={formErrors}
              />

              <InputSection
                label="Prénom du ou de la DR"
                inputId="modificationEtab_prenomDR"
                inputType="text"
                placeholder="Prénom du ou de la DR"
                name="prenom_dr"
                required
                register={register}
                formErrors={formErrors}
              />
            </section>
          </section>
        </article>

        <section className={styles.informationEtabModification__bottom}>
          <article className={styles.informationEtabModification__bottom__DE}>
            <h2>Informations Directeur(trice) d'établissement *</h2>
            <main>
              <section>
                <InputSection
                  label="NOM du ou de la DE"
                  inputId="modificationEtab_nomDE"
                  inputType="text"
                  placeholder="NOM du ou de la DE"
                  name="nom_de"
                  required
                  register={register}
                  formErrors={formErrors}
                />

                <PhoneSection
                  label="Téléphone"
                  inputId="modificationEtab_phoneDE"
                  placeholder="0 00 00 00 00"
                  name="phone_de"
                  required
                  register={register}
                  formErrors={formErrors}
                  setRegisterValue={setRegisterValue}
                />
              </section>
              <section>
                <InputSection
                  label="Prénom du ou de la DE"
                  inputId="modificationEtab_prenomDE"
                  inputType="text"
                  placeholder="Prénom du ou de la DE"
                  name="prenom_de"
                  required
                  register={register}
                  formErrors={formErrors}
                />
                <InputSection
                  label="Email"
                  inputId="modificationEtab_emailDE"
                  inputType="text"
                  placeholder="prénom.nom@clariane.com ou @inicea.fr"
                  name="email_de"
                  required
                  register={register}
                  formErrors={formErrors}
                />
              </section>
            </main>
          </article>
          <article className={styles.informationEtabModification__bottom__REF}>
            <h2>Informations Référent(e) Vis ma vie *</h2>
            <main>
              <section>
                <InputSection
                  label="NOM du ou de la référent(e)"
                  inputId="modificationEtab_nomRef"
                  inputType="text"
                  placeholder="NOM du ou de la référent(e)"
                  name="nom_referent"
                  required
                  register={register}
                  formErrors={formErrors}
                />

                <InputSection
                  label="Email"
                  inputId="modificationEtab_emailRef"
                  inputType="text"
                  placeholder="prénom.nom@clariane.com ou @inicea.fr"
                  name="email_referent"
                  required
                  register={register}
                  formErrors={formErrors}
                />
              </section>
              <section>
                <InputSection
                  label="Prénom du ou de la référent(e)"
                  inputId="modificationEtab_prenomRef"
                  inputType="text"
                  placeholder="Prénom du ou de la référent(e)"
                  name="prenom_referent"
                  required
                  register={register}
                  formErrors={formErrors}
                />

                <PhoneSection
                  label="Téléphone"
                  inputId="modificationEtab_emailRef"
                  placeholder="0 00 00 00 00"
                  name="phone_referent"
                  required
                  register={register}
                  formErrors={formErrors}
                  setRegisterValue={setRegisterValue}
                />
              </section>
            </main>
          </article>
        </section>
      </main>
      <footer className={styles.informationEtabModification__footer}>
        <Button
          buttonBackGroundColor="white"
          text="Annuler les modifications"
          textColor="dark"
          onClick={onClickAnnulerModification}
        />
        <Button
          buttonBackGroundColor="blue"
          text="Enregistrer les modifications"
          textColor="white"
          type="submit"
          icon={whiteCheckIcon}
          onClick={onClickEnregister && onClickEnregister}
        />
      </footer>
    </article>
  );
}
