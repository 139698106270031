import { EventType, InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { NotificationsProvider } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useEffect } from "react";
import RouterContainer from "./RouterContainer";
import {
  msalConfigB2CPolicies,
  msalInstance,
} from "./configurations/auth/msal.auth.configuration";
import Login from "./pages/login/Login";

// My sincere apologies for all the prop drilling, I had to move fast
function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider position="top-center">
        <MsalProvider instance={msalInstance}>
          <MsalCallback />
          <AuthenticatedTemplate>
            <RouterContainer />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
          <ReactQueryDevtools />
        </MsalProvider>
      </NotificationsProvider>
    </QueryClientProvider>
  );
}

export default App;

const MsalCallback = () => {
  const { instance } = useMsal();

  useEffect(() => {
    try {

      const callbackId = instance.addEventCallback(async (event: any) => {
        if (event.eventType === EventType.LOGIN_FAILURE) {
          alert(
            "Oups ! Vous n'avez pas les droits nécessaires pour accéder à la plateforme, merci d'envoyer un email à l'adresse vismavie@clariane.com afin que l'administrateur puisse débloquer rapidement la situation."
          );
          if (
            event.error &&
            event.error.errorMessage.indexOf("AADB2C90118") > -1
          ) {
            if (event.interactionType === InteractionType.Redirect) {
              await instance.loginRedirect(
                msalConfigB2CPolicies.authorities.forgotPassword
              );
            } else if (event.interactionType === InteractionType.Popup) {
              instance
                .loginPopup(msalConfigB2CPolicies.authorities.forgotPassword)
                .catch(() => {
                  return;
                });
            }
          }
        }

        if (event.eventType === EventType.LOGIN_SUCCESS) {
          if (event?.payload) {
            if (event.payload.idToken && event.payload.idTokenClaims.oid) {
              // uuuhhhhh

            } else {
              return instance.logout();
            }
            if (
              event.payload.idTokenClaims["acr"] ===
              msalConfigB2CPolicies.names.forgotPassword
            ) {
              window.alert(
                "Password has been reset successfully. \nPlease sign-in with your new password"
              );
              return instance.logout();
            }
          }
        }
      });
      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      }

    } catch (error) {
      console.log('ERROR', error);

    }


  }, [instance]);
  return <></>;
};
