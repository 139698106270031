import { useQuery } from "@tanstack/react-query";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { usePagesToDisplayStore } from "../../stores/pages-to-display/pages-to-display.store";
import usersServices from "../../services/users.service";
import { useTypeEtablissementStore } from "../../stores/misc/type-etablissement.store";

// Get by oid
export function useGetEtablissementByOID(oid: string) {
  const setEtablissement = useEtablissementStore(
    (state) => state.setEtablissement
  ); // zustand state

  const setIsModificationFlow = usePagesToDisplayStore(
    (state) => state.setIsModificationFlow
  );

  const { setOldValueTypeEtablissement, oldValueTypeEtablissement } =
    useTypeEtablissementStore();
  return useQuery({
    queryKey: ["etablissements", oid],
    queryFn: () => usersServices.getEtablissementByOID(oid),
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (fetchedEtablissement) => {
      setOldValueTypeEtablissement(fetchedEtablissement?.data.type);

      if (fetchedEtablissement?.data) {
        // the request can be successfull even if it returns an empty object
        setEtablissement(fetchedEtablissement?.data);
        setIsModificationFlow(true);
      }
      if (!fetchedEtablissement?.data) {
        setIsModificationFlow(false);
        return;
      }
    },
    onError: () => {
      setIsModificationFlow(false);
    },
  });
}
