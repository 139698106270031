import { Modal } from "@mantine/core";
import { closeIcon } from "../../../assets/svgs/icons/icons.svg";
import animationBlockScreenshot from "../../../assets/png/animation_block_screen.png";

import styles from "./ChangeEtablissementTypePopup.module.scss";
import Button from "../Button/Button";
type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function ChangeEtablissementTypePopup(props: Props) {
  const { isOpen = false, setIsOpen } = props;
  function onClickClose() {
    setIsOpen(false);
  }
  return (
    <Modal
      opened={isOpen}
      onClose={onClickClose}
      centered
      withCloseButton={false}
      size="auto"
    >
      <article className={styles.changeEtablissementTypePopup}>
        <header className={styles.changeEtablissementTypePopup__header}>
          <h1>Vos modifications ont bien été enregistrées</h1>
          <button type="button" onClick={onClickClose}>
            {closeIcon}
          </button>
        </header>
        <main className={styles.changeEtablissementTypePopup__main}>
          <section
            className={styles.changeEtablissementTypePopup__main__textBloc}
          >
            <p>
              Vous venez de nous indiquer que votre établissement est un EHPAD.
            </p>
            <p>
              De nouvelles <strong>possibilités d'accueils</strong> sont à
              renseigner en <strong>Animation</strong>.
            </p>
            <p>
              Rendez-vous un peu plus bas dans la page de{" "}
              <strong>votre inscription</strong> , dans l'encart{" "}
              <strong>Possibilités d'accueils.</strong>
            </p>
          </section>
          <section
            style={{ backgroundImage: `url(${animationBlockScreenshot})` }}
            className={styles.changeEtablissementTypePopup__main__screen}
          ></section>
        </main>
    
      </article>
    </Modal>
  );
}
