import InputSection from "../../../global/InputSection/InputSection";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { EtablissementValidationType } from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import PhoneSection from "../../../global/PhoneSection/PhoneSection";

import styles from "./InformationDE.module.scss";

type Props = {
  register: UseFormRegister<EtablissementValidationType>;
  formErrors: FieldErrors<EtablissementValidationType>;
  setRegisterValue: UseFormSetValue<any>; // TODO : Remove 'any' type

};
function InformationDE(props: Props) {
  const { register, formErrors,setRegisterValue } = props;

  return (
    <article className={styles.inscriptionEtablissement__main__infoDE}>
      <h2>Informations Directeur(trice) d’établissement *</h2>
      <article
        className={styles.inscriptionEtablissement__main__infoDE__inputs}
      >
        <section>
          <InputSection
            label="NOM du ou de la DE"
            inputId="inscriptionEtab_infoDE_nomDE"
            inputType="text"
            placeholder="Nom du ou de la DE"
            name="nom_de"
            required
            register={register}
            formErrors={formErrors}
            
          />

          <InputSection
            label="Prénom du ou de la DE"
            inputId="inscriptionEtab_infoDE_prenomDE"
            inputType="text"
            placeholder="Prénom du ou de la DE"
            name="prenom_de"
            required
            register={register}
            formErrors={formErrors}
            
          />
        </section>
        <section>
          <InputSection
            label="Email"
            inputId="inscriptionEtab_infoDE_emailDE"
            inputType="text"
            placeholder="prenom.nom@clariane.com"
            name="email_de"
            required
            register={register}
            formErrors={formErrors}
            
          />
          <PhoneSection
            label="Téléphone"
            inputId="inscriptionEtab_infoDE_telephoneDE"
            placeholder="0 00 00 00 00"
            name="phone_de"
            required
            register={register}
            formErrors={formErrors}
            setRegisterValue={setRegisterValue}
            
          />
        </section>
      </article>
    </article>
  );
}

export default InformationDE;
