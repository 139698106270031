import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import styles from "./PhoneSection.module.scss";

type Props = {
  label: string;
  placeholder: string;
  inputId: string;
  name: string;
  required?: boolean;
  register: UseFormRegister<any>; // TODO : Remove 'any' type
  formErrors: DeepMap<any, FieldError>;
  setRegisterValue: UseFormSetValue<any>;
};
export default function PhoneSection(props: Props) {
  const {
    label,
    placeholder,
    inputId,
    name,
    register,
    formErrors,
  } = props;

  const errorName = formErrors?.[name];
  return (
    <article className={styles.phoneSection}>
      {" "}
      <label className={styles.phoneSection__titleLabel} htmlFor={inputId}>
        {label}
      </label>
      <section className={styles.phoneSection__phoneContainer}>
        <section className={styles.phoneSection__phoneContainer__phoneCode}>
          +33
        </section>
        <input
          type="tel"
          id={inputId}
          placeholder={placeholder}
          {...register(name)}
          has-error={errorName ? "yes" : "no"} // check the style sheet
        />
      </section>
      <label className={styles.phoneSection__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message}
      </label>
    </article>
  );
}
