import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { profileLogoSvg } from "../../../assets/svgs/icons/icons.svg";
import { msalInstance } from "../../../configurations/auth/msal.auth.configuration";

import styles from "./Header.module.scss";
import { korianLogo } from "../../../assets/svgs/logos/logo_2.svg";
const Header = () => {
 const {accounts} = useMsal()
 const user = accounts[0]
  const firstName = user?.name?.split(' ')[0] || 'Prénom'
  const lastName = user?.name?.split(' ')[1] || 'NOM'
  return (
    <header className={styles.headerTopBar}>
      {/* <article className={styles.headerTopBar__container}> */}
      <section className={styles.headerTopbar__left}>
        <h1>{korianLogo}</h1>
      </section>
      <section className={styles.headerTopbar__right}>
        <Link className={styles.headerTopbar__right__faqButton} to="/FAQ">
          FAQ
        </Link>
          <section className={styles.headerTopbar__right__profileInfo}>
            <section
              className={styles.headerTopbar__right__profileIfo_logo}
            >
              {profileLogoSvg}
            </section>
            <p
              className={styles.headerTopbar__right__profileIfo_nomPrenom}
            >
              {lastName} {firstName}
            </p>
          </section>
          <section className={styles.headerTopbar__right__deconnexion}>
            <a href="/" onClick={async () => {
              localStorage.setItem('accessToken','null')
              await msalInstance.logoutRedirect()
              }}>Déconnexion</a>
          </section>
       
      </section>
      {/* </article> */}
    </header>
  );
};

export default Header;
