import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { useMsal } from "@azure/msal-react";

import EtablissementInscriptionPage from "./pages/etablissement";
import EtablissementRecapPage from "./pages/etablissement/recapitulatif/EtablissementRecapPage";
import EtablissementDemandeEnvoyePage from "./pages/etablissement/demande-envoyee/EtablissementDemandeEnvoyePage";
import EtablissementConfirmationPage from "./pages/etablissement/confirmation/EtablissementConfirmationPage";
import { FAQPage } from "./pages/FAQ";
import PageNotFound from "./pages/error/PageNotFound/PageNotFound";
import Layout from "./components/layout/Layout";
import { usePagesToDisplayStore } from "./stores/pages-to-display/pages-to-display.store";
import Loader from "./components/global/Loader/Loader";
import { useGetEtablissementByOID } from "./hooks/react-query/users.queries";
import usersServices from "./services/users.service";
import { msalInstance } from "./configurations/auth/msal.auth.configuration";
import { getToken, getUserEmailAndOID } from "./utils";

export default function RouterContainer() {
  //* useState
  const [isLoadingToken, setIsLoadingToken] = useState<boolean>(true);

  //* msal-react
  const { accounts } = useMsal();
  const { oid, userEmail } = getUserEmailAndOID(accounts);

  //* react-query
  const { isLoading } = useGetEtablissementByOID(oid);

  //* zustand
  const { isModificationFlow } = usePagesToDisplayStore();
  const { pagesToDisplay } = usePagesToDisplayStore();

  useEffect(() => {
    getToken(
      userEmail as string,
      msalInstance,
      usersServices.getAccessToken
    ).then((token) => {
      localStorage.setItem("accessToken", token || "-");
      setIsLoadingToken(false);
    });
  }, [accounts[0]]);

  if (isLoading || isLoadingToken) return <Loader />;
  //   TODO add boolean useState in zustand to protect access to recap and demande envoyee pages before the user has completed the first step
  // const isModificationFlow = false; // !TODO fetch the etablissement from the backend in login page and update this value dynamicaly
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* <Route path="/" element={<Layout />}> */}
        {!isModificationFlow ? (
          <>
            <Route path="">
              <Route index element={<EtablissementInscriptionPage />} />
              {pagesToDisplay.includes("recapitulatif") && (
                <Route
                  path="recapitulatif"
                  element={<EtablissementRecapPage />}
                />
              )}
            </Route>
          </>
        ) : (
          <>
            <Route path="/">
              <Route index element={<EtablissementConfirmationPage />} />

              {pagesToDisplay.includes("demande-envoyee") && (
                <Route
                  path="demande-envoyee"
                  element={<EtablissementDemandeEnvoyePage />}
                />
              )}
            </Route>
          </>
        )}

        <Route path="FAQ" element={<FAQPage />} />
        {/* </Route> */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}
