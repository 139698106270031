import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import {
  checkIcon,
  flecheDroiteIcon,
  telechargerIcon,
} from "../../../assets/svgs/icons/icons.svg";
import InformationEtabRecap from "../../../components/etablissement/recapitulatif/InformationEtabRecap/InformationEtabRecap";
import PossibilitesAccueilsEtabRecap from "../../../components/etablissement/recapitulatif/PossibilitesAccueilsEtabRecap/PossibilitesAccueilsEtabRecap";
import Button from "../../../components/global/Button/Button";
import LinkButton from "../../../components/global/Button/LinkButton/LinkButton";
import {
  IEtablissement,
  Metier,
  TypeEtablissement,
} from "../../../schemas-and-types/etablissement/etablissemen.schema";
import { useEtablissementStore } from "../../../stores/etablissement/etablissement.store";

import FooterBackGroundImage from "../../../assets/png/error-page-decoration.png";
import { scrollToAnchor } from "../../../utils";
import styles from "./EtablissementDemandeEnvoyePage.module.scss";

export default function EtablissementDemandeEnvoyePage() {
  const etablisement = useEtablissementStore((state) => state.etablissement);
  // * react-to-print
  const componentToPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  useEffect(() => {
    scrollToAnchor(document.getElementById("demande-envoyee-anchor"));
  }, []);
  return (
    <article
      id="demande-envoyee-anchor"
      className={styles.etablissementDemandeEnvoyePage}
    >
      <header className={styles.etablissementDemandeEnvoyePage__header}>
        <section>
          <figure>{checkIcon}</figure>
          <h1>Inscription envoyée</h1>
        </section>
        <p>
          Nous avons bien pris en compte votre inscription au projet Vis ma Vie
          et nous vous en remercions. Un mail de confirmation vous a été envoyé.
        </p>
      </header>
      <main className={styles.etablissementDemandeEnvoyePage__main}>
        <section
          className={
            styles.etablissementDemandeEnvoyePage__main__topButtonSection
          }
        >
          <Button
            text="Télécharger"
            icon={telechargerIcon}
            buttonBackGroundColor="white"
            textColor="blue"
            customBorderColor="blue"
            onClick={handlePrint}
          />
        </section>
        <section ref={componentToPrintRef}>
          <InformationEtabRecap
            canUpdate={false}
            // etablissement={etablissementData?.data as IEtablissement}
            etablissement={etablisement as IEtablissement}
          />
          <PossibilitesAccueilsEtabRecap
            canUpdate={false}
            // metiers={etablissementData?.data?.metiers as Metier[]}
            metiers={etablisement?.metiers as Metier[]}

            typeEtablissement={etablisement?.type as TypeEtablissement}
          />
        </section>
        <Button
          text="Télécharger"
          icon={telechargerIcon}
          buttonBackGroundColor="white"
          textColor="blue"
          customBorderColor="blue"
          onClick={handlePrint}
        />
        <LinkButton
          to={"/"}
          text="Revenir à la page d'accueil"
          iconRight={flecheDroiteIcon}
        />
      </main>
      <footer
        style={{ backgroundImage: `url(${FooterBackGroundImage})` }}
      ></footer>
    </article>
  );
}
