import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { AxiosResponse } from "axios";
import {
  Etablissement,
  TypeEtablissement,
} from "../../../schemas-and-types/etablissement/etablissemen.schema";
import etablissementServices from "../../../services/etablissement.service";
import mailingServiceService from "../../../services/mailing-service/mailing-service.service";
import { useEtablissementStore } from "../../../stores/etablissement/etablissement.store";
import { usePagesToDisplayStore } from "../../../stores/pages-to-display/pages-to-display.store";

import { isTypeEtablissementChangeToEHPAD } from "../../../pages/etablissement/recapitulatif/utils";
import { useTypeEtablissementStore } from "../../../stores/misc/type-etablissement.store";
import { useNotificationStore } from "../../../stores/notifications/notifications.store";
import {
  generateCancelInscriptionEmail,
  generateInscriptionEmail,
} from "./email-formats";
import { getUserInfo, showNotif } from "./utils";

// Get List
export function useGetEtablissements(page: number) {
  return useQuery({
    queryKey: ["etablissements", { page: page }],
    queryFn: () => etablissementServices.getEtablissements(page),
    keepPreviousData: true,
  });
}

// Get by id
export function useGetEtablissementById(id: string) {
  const setEtablissement = useEtablissementStore(
    (state) => state.setEtablissement
  ); // zustand state

  const setIsModificationFlow = usePagesToDisplayStore(
    (state) => state.setIsModificationFlow
  );
  return useQuery({
    queryKey: ["etablissements", id],
    queryFn: () => etablissementServices.getEtablissementBydId(id),
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (fetchedEtablissement) => {
      if (fetchedEtablissement?.data) {
        // the request can be successfull even if it returns an empty object
        setEtablissement(fetchedEtablissement?.data);
        setIsModificationFlow(true);
      }
      if (!fetchedEtablissement?.data) {
        setIsModificationFlow(false);
        return;
      }
    },
    onError: () => {
      setIsModificationFlow(false);
    },
  });
}

// Add
export function useAddEtablissement(
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const queryClient = useQueryClient();

  // * react-router-dom
  const navigate = useNavigate();

  // * zustand
  const setEtablissement = useEtablissementStore(
    (state) => state.setEtablissement
  );

  const setPagesToDisplay = usePagesToDisplayStore(
    (state) => state.setPagesToDisplay
  );

  // * User info for Mailing
  const { accounts } = useMsal();
  const { nom, prenom, userEmail } = getUserInfo(accounts);

  return useMutation({
    mutationFn: etablissementServices.addEtablissement,
    onSuccess: async (newEtab: AxiosResponse<Etablissement, any>) => {
      const addedEtablissement: Etablissement = newEtab.data;
      queryClient.invalidateQueries(["etablissements"]);

      setIsLoading && setIsLoading(false);
      setEtablissement(addedEtablissement);

      await mailingServiceService.sendEmail({
        email: userEmail,
        subject: "Votre inscription à l'évènement Vis ma Vie.",
        body: generateInscriptionEmail(prenom, nom, addedEtablissement),
      });

      navigate("/demande-envoyee");
      setPagesToDisplay(["demande-envoyee", "recapitulatif"]);
      showNotif("Votre demande a bien été prise en compte");
    },

    onError: () => {
      setIsLoading && setIsLoading(false);
    },
  });
}

// Update
export function useUpdateEtablissement(
  typeEtablissement: TypeEtablissement,
  setChangeEtabIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsInfoEtabModification: React.Dispatch<React.SetStateAction<boolean>>,
  setIsPossibiliteAccueilModification: React.Dispatch<
    React.SetStateAction<boolean>
  >
) {
  const queryClient = useQueryClient();

  // * react-router-dom
  const navigate = useNavigate();

  // * zustand
  const { setEtablissement } = useEtablissementStore();
  const { setOldValueTypeEtablissement, oldValueTypeEtablissement } =
    useTypeEtablissementStore();
  return useMutation({
    mutationFn: etablissementServices.updateEtablissement,
    onSuccess: (etablissementResponse: AxiosResponse<Etablissement, any>) => {
      const updatedEtablissement: Etablissement = etablissementResponse.data;
      queryClient.invalidateQueries(["etablissements"]);
      setIsLoading(true);
      // console.log('inside queries',updatedEtablissement)
      // setEtablissement(updatedEtablissement);
      setIsInfoEtabModification(false);
      setIsPossibiliteAccueilModification(false);

      if (
        isTypeEtablissementChangeToEHPAD(
          oldValueTypeEtablissement as TypeEtablissement,
          typeEtablissement
        )
      ) {
        setChangeEtabIsPopupOpen(true);
      }

      setIsLoading && setIsLoading(false);
    },
    onError: () => {
      setIsLoading && setIsLoading(false);
    },
  });
}

// Delete
export function useDeleteEtablissement(
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const navigate = useNavigate();
  const setEtablissement = useEtablissementStore(
    (state) => state.setEtablissement
  );

  // * notification state
  const { setShowCancelInscriptionNotification } = useNotificationStore();

  // * User info for Mailing
  const { accounts } = useMsal();
  const { nom, prenom, userEmail } = getUserInfo(accounts);

  return useMutation({
    mutationFn: etablissementServices.deleteEtablissement,
    onSuccess: async () => {

      setEtablissement("any");
      setIsLoading && setIsLoading(false);
      await mailingServiceService.sendEmail({
        email: userEmail,
        subject: "Annulation de votre inscription à l'évènement Vis ma Vie.",
        body: generateCancelInscriptionEmail(prenom, nom),
      });

      setShowCancelInscriptionNotification(true);
      // Reload page

      navigate(0); // same as window.location.reload()
    },
    onError: () => {
      setIsLoading && setIsLoading(false);
    },
  });
}
