import { crayonIcon } from "../../../../assets/svgs/icons/icons.svg";
import {
  Metier,
  Metier as MetierType,
  TypeEtablissement,
} from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import Button from "../../../global/Button/Button";
import MetierRecap from "./MetierRecap/MetierRecap";

import { useEffect } from "react";
import {
  disponiblitesDeuxiemeSemaine,
  disponiblitesPremiereSemaine,
  disponiblitesQuatriemeSemaine,
  disponiblitesTroisiemeSemaine
} from "../../../../data/possibilitesAccueils.data";
import styles from "./PossibilitesAccueilsEtabRecap.module.scss";

type Props = {
  canUpdate: boolean;
  setIsPossibiliteAccueilModification?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  metiers: Metier[];
  setMetiers?: React.Dispatch<React.SetStateAction<MetierType[]>>;

  typeEtablissement: TypeEtablissement;
};

export default function PossibilitesAccueilsEtabRecap(props: Props) {
  const {
    canUpdate,
    metiers,
    setMetiers,
    setIsPossibiliteAccueilModification,
    typeEtablissement,
  } = props;

  function onClickModifier() {
    setIsPossibiliteAccueilModification &&
      setIsPossibiliteAccueilModification(true);
  }

  useEffect(() => {
    if (typeEtablissement !== "EHPAD") {
      setMetiers &&
        setMetiers((current) =>
          current.filter((metier) => metier.title !== "Animation")
        );
      return;
    } else {
      if (!metiers.some((metier) => metier.title === "Animation")) {
        setMetiers &&
          setMetiers((current) => [
            ...current,
            {
              title: "Animation",
              job_title: "Animateur(trice)",
              premiere_semaine: disponiblitesPremiereSemaine,
              deuxieme_semaine: disponiblitesDeuxiemeSemaine,
              troisieme_semaine: disponiblitesTroisiemeSemaine,
              quatrieme_semaine: disponiblitesQuatriemeSemaine,
              isDisponible: true
            },
          ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeEtablissement]);
  return (
    <article className={styles.possibilitesAccueilsEtabRecap}>
      <header className={styles.possibilitesAccueilsEtabRecap__header}>
        <h1>Possibilité(s) d'accueil(s) *</h1>
        {canUpdate && (
          <Button
            buttonBackGroundColor="white"
            textColor="dark"
            type="button"
            icon={crayonIcon}
            text="Modifier"
            onClick={onClickModifier}
          />
        )}
      </header>
      <main className={styles.possibilitesAccueilsEtabRecap__main}>
        {metiers &&
          metiers?.map((metierItem: MetierType, index: number) => {
            if (
              typeEtablissement !== "EHPAD" &&
              metierItem.title === "Animation"
            )
              // eslint-disable-next-line array-callback-return
              return;

            return (
              <MetierRecap
                key={`MetierRecap__${index}`}
                title={metierItem.title}
                job_title={metierItem.job_title}
                premiere_semaine={metierItem.premiere_semaine}
                deuxieme_semaine={metierItem.deuxieme_semaine}
                troisieme_semaine={metierItem.troisieme_semaine}
                quatrieme_semaine={metierItem.quatrieme_semaine}
              />
            );
          })}
      </main>
    </article>
  );
}
