import { useMsal } from "@azure/msal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import InformationEtabRecap from "../../../components/etablissement/recapitulatif/InformationEtabRecap/InformationEtabRecap";
import PossibilitesAccueilsEtabRecap from "../../../components/etablissement/recapitulatif/PossibilitesAccueilsEtabRecap/PossibilitesAccueilsEtabRecap";
import { useAddEtablissement } from "../../../hooks/react-query/etablissement/etablissement.queries";

import {
  Etablissement,
  EtablissementValidationSchema,
  EtablissementValidationType,
  IEtablissement,
  Localisation,
  Metier,
  TypeEtablissement,
} from "../../../schemas-and-types/etablissement/etablissemen.schema";
import { useEtablissementStore } from "../../../stores/etablissement/etablissement.store";

import PossiblitesAccueils from "../../../components/etablissement/InscriptionEtablissement/PossiblitesAccueils/PossiblitesAccueils";
import InformationEtabModification from "../../../components/etablissement/modification-flow/InformationEtabModification/InformationEtabModification";
import ActionPopup from "../../../components/global/ActionPopup/ActionPopup";
import Loader from "../../../components/global/Loader/Loader";
import { useGetRegions } from "../../../hooks/react-query/dropdowns/regions.queries";
import { useGetTypeEtablissement } from "../../../hooks/react-query/dropdowns/type-etablissement.queries";
import { getInitialValuesOfEtablissement } from "../confirmation/utils";

import FooterBackGroundImage from "../../../assets/png/error-page-decoration.png";
import { envoyerVotreInscriptionIcon } from "../../../assets/svgs/icons/icons.svg";
import { executeAddressNotValideSideEffect } from "../../../components/etablissement/InscriptionEtablissement/utils";
import Button from "../../../components/global/Button/Button";
import ChangeEtablissementTypePopup from "../../../components/global/ChangeEtablissementTypePopup/ChangeEtablissementTypePopup";
import { useTypeEtablissementStore } from "../../../stores/misc/type-etablissement.store";
import { usePagesToDisplayStore } from "../../../stores/pages-to-display/pages-to-display.store";
import { scrollToAnchor } from "../../../utils";
import styles from "./EtablissementRecapPage.module.scss";
import {
  formatEtablissementToUpdate,
  formatEtablissementToUpdateUI,
  isTypeEtablissementChangeToEHPAD
} from "./utils";

export default function EtablissementRecapPage() {
  // * msal
  const { accounts } = useMsal();
  const oid = accounts[0].idTokenClaims?.oid as string;

  // * react-router-dom
  const navigate = useNavigate();

  // * Zustand
  const { etablissement } = useEtablissementStore();
  const { setEtablissement } = useEtablissementStore();
  const { setPagesToDisplay } = usePagesToDisplayStore();
  const { oldValueTypeEtablissement, setOldValueTypeEtablissement } =
    useTypeEtablissementStore();

  // * useState
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isChangeEtabPopupOpen, setChangeEtabIsPopupOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [localisationOption, setLocalisationOption] = useState<Localisation>(
    etablissement?.localisation as Localisation
  );
  const [isInfoEtabModification, setIsInfoEtabModification] =
    useState<boolean>(false);
  const [
    isPossibiliteAccueilModification,
    setIsPossibiliteAccueilModification,
  ] = useState<boolean>(false);
  const [metiers, setMetiers] = useState<Metier[]>(
    etablissement?.metiers as Metier[]
  );

  // * react-query

  // mutation function
  const { mutate } = useAddEtablissement(setIsLoading);

  //* react-hook-form
  const {
    register,
    handleSubmit,
    formState,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm<EtablissementValidationType>({
    resolver: yupResolver(EtablissementValidationSchema),
    values: {
      ...getInitialValuesOfEtablissement(etablissement as IEtablissement),
      address: etablissement?.localisation?.address ?? "",
    },
  });
  const formErrors = formState.errors;
  const watchTypeEtablissement = watch("type");
  //* auto on submit when any 'modifier' button is clicked
  function onSubmit(data: EtablissementValidationType) {
    const etablissementToAdd = formatEtablissementToUpdateUI(
      data,
      localisationOption,
      oid,
      metiers
    );

    setEtablissement(etablissementToAdd as Etablissement);
    setIsInfoEtabModification(false);
    setIsPossibiliteAccueilModification(false);

    if (
      isTypeEtablissementChangeToEHPAD(
        oldValueTypeEtablissement as TypeEtablissement,
        watchTypeEtablissement as TypeEtablissement
      )
    ) {
      setChangeEtabIsPopupOpen(true);
    }
    setOldValueTypeEtablissement(watchTypeEtablissement);
  }

  // * The following function is trigerred when
  // * the confirm button of the add etablissement popup is clicked
  function onConfirmSubmit() {
    // ADD ETABLISSEMENT

    setIsLoading(true);
    const etablissementToAdd = formatEtablissementToUpdate(
      etablissement as IEtablissement,
      localisationOption,
      oid,
      metiers
    );
    mutate(etablissementToAdd)
    setPagesToDisplay(["demande-envoyee", "recapitulatif"]);
    navigate("/")
  }

  useEffect(() => {
    setPagesToDisplay(["recapitulatif"]);
    scrollToAnchor(document.getElementById("recap-anchor"));

    if (localisationOption.address === "") {
      executeAddressNotValideSideEffect(setError);
      return;
    }

    // this executes when the user changes the type of etablissement
    // if (watchTypeEtablissement !== "EHPAD") {
    //   removeAnimationBlock(
    //     setMetiers,
    //     disponiblitesPremiereSemaine,
    //     disponiblitesDeuxiemeSemaine
    //   );
    // }
  }, [watchTypeEtablissement, oldValueTypeEtablissement]);

  function openConfirmationPopup() {
    setIsPopupOpen(true);
  }

  // Get the options for the dropdowns
  const {
    data: typeEtablissementsOptions,
    isLoading: areTypeEtabOptionsLoading,
  } = useGetTypeEtablissement();

  const { data: regionsOptions, isLoading: areRegionOptionsLoading } =
    useGetRegions();
  if (isLoading) return <Loader />;
  return (
    <article id="recap-anchor" className={styles.etablissementRecapPage}>
      <header className={styles.etablissementRecapPage__header}>
        <h1>Récapitulatif de votre inscription</h1>
        <p>
          Merci d'avoir indiqué vos disponibilités. Nous vous inventons à
          vérifier vos informations avant d'envoyer votre inscription.
        </p>
      </header>
      <main className={styles.etablissementRecapPage__main}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isInfoEtabModification ? (
            <InformationEtabModification
              register={register}
              formErrors={formErrors}
              setIsInfoEtabModification={setIsInfoEtabModification}
              setError={setError}
              clearErrors={clearErrors}
              setRegisterValue={setValue}
              localisationOption={localisationOption}
              setLocalisationOption={setLocalisationOption}
              typeEtablissementsOptions={typeEtablissementsOptions}
              areTypeEtabOptionsLoading={areTypeEtabOptionsLoading}
              regionsOptions={regionsOptions}
              areRegionOptionsLoading={areRegionOptionsLoading}
            // onClickEnregister={() =>
            //   setOldValueTypeEtablissement(typeEtablissement)
            // }
            />
          ) : (
            <InformationEtabRecap
              canUpdate={true}
              setIsInfoEtabModification={setIsInfoEtabModification}
              etablissement={etablissement as IEtablissement}
            />
          )}

          {/* Possibilité(s) d’accueil(s) */}

          {/*  Here we render either the ossiblitesAccueils component which holds inputs that can be modified, */}
          {/* or the one which only displays data */}
          {isPossibiliteAccueilModification ? (
            <PossiblitesAccueils
              setIsPossibiliteAccueilModification={
                setIsPossibiliteAccueilModification
              }
              metiers={metiers}
              setMetiers={setMetiers}
              typeEtablissement={watchTypeEtablissement as TypeEtablissement}
              onClickEnregister={() => navigate(0)}
            />
          ) : (
            <PossibilitesAccueilsEtabRecap
              canUpdate={true}
              setIsPossibiliteAccueilModification={
                setIsPossibiliteAccueilModification
              }
              metiers={metiers}
              setMetiers={setMetiers}
              typeEtablissement={watchTypeEtablissement as TypeEtablissement}
            />
          )}
        </form>
        <Button
          type="submit"
          text="Envoyer votre inscription"
          rightIcon={envoyerVotreInscriptionIcon}
          onClick={openConfirmationPopup}
          buttonBackGroundColor="linear-blue"
          textColor="white"
        />
      </main>
      <footer
        style={{ backgroundImage: `url(${FooterBackGroundImage})` }}
      ></footer>
      <ActionPopup
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        title="Votre inscription va être envoyée"
        textArray={[
          `Vous avez jusqu'au ${process.env.REACT_APP_DATE_END_INSCRIPTION_LITERAL} pour modifier vos informations.`,
          "Une fois ce délai passé, merci d'envoyer toutes modifications de disponibilités à l'adresse mail : vismavie@clariane.com .",
          "Êtes vous sûr(e) de vouloir envoyer votre demande de participation ?",
        ]}
        type="confirm"
        cancelButtonText="Annuler"
        confirmButtonText="Envoyer"
        onConfirm={onConfirmSubmit}
        key={"action_popup_pagerecap"}
      />
      <ChangeEtablissementTypePopup
        isOpen={isChangeEtabPopupOpen}
        setIsOpen={setChangeEtabIsPopupOpen}
        key={"change_etablissement_popup_pagerecap"}
      />
    </article>
  );
}
