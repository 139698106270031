import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface NewTypeEtablissementState {
  oldValueTypeEtablissement: string;
  setOldValueTypeEtablissement: (newValue: string) => void;
}

export const useTypeEtablissementStore = create<NewTypeEtablissementState>()(
  devtools(
    persist(
      (set) => ({
        oldValueTypeEtablissement: "",
        setOldValueTypeEtablissement: (newState) =>
          set((state) => ({ oldValueTypeEtablissement: newState })),
      }),
      {
        name: "notification-state-storage",
      }
    )
  )
);
