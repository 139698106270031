import { emailSignatureBASE64 } from "../../../assets/base64/email-signature";
import { Etablissement } from "../../../schemas-and-types/etablissement/etablissemen.schema";

const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

export function generateInscriptionEmail(
  prenom: string,
  nom: string,
  etablissementInfo: Etablissement
) {
  return `<article>
      <p>
        Bonjour ${prenom} ${nom},
      </p>
      <p>
        Vous venez d'enregistrer ${etablissementInfo.name} sur le portail “Vis
        ma vie” et nous vous en remercions.
      </p>
      <p>Vous trouverez ci-dessous le récapitulatif de votre inscription.</p>
      <p>
        Si cela s'avère nécessaire, vous pouvez corriger la saisie en vous
        connectant à nouveau sur la plateforme. La date limite pour le faire est
        le ${process.env.REACT_APP_DATE_END_INSCRIPTION_LITERAL}. Au-delà, merci de contacter directement le collaborateur
        qui ne pourrait plus être accueilli dans votre établissement.
      </p>
      <p>Bien à vous.</p>
      <p>L'équipe projet “Vis ma vie”</p>
      <br></br>
      <br></br>
      <h3>Récapitulatif de votre inscription :</h3>
      <br></br>
      <strong>Informations établissement</strong>
      <p>Type d'établissement : ${etablissementInfo.type}</p>
      <p>Nom de l'établissement : ${etablissementInfo.name}</p>
      <p>
        Adresse de l'établissement : ${etablissementInfo.localisation.address}
      </p>
      <p>
        Accès établissement et transports : ${etablissementInfo.information_access
    }
      </p>
      <p>
        Région opérationnelle de rattachement de l'établissement : ${etablissementInfo.information_regional.region
    }
      </p>
      <p>
        NOM du ou de la DR : ${etablissementInfo.information_regional.nom_dr}
      </p>
      <p>
        Prénom du ou de la DR : ${etablissementInfo.information_regional.prenom_dr
    }
      </p>
      <br></br>
      <strong>Informations Directeur(trice) d'établissement</strong>
      <p>NOM du ou de la DE : ${etablissementInfo.information_DE.nom_de}</p>
      <p>
        Prénom du ou de la DE : ${etablissementInfo.information_DE.prenom_de}
      </p>
      <p>Email : ${etablissementInfo.information_DE.email_de}</p>
      <p>Téléphone : ${etablissementInfo.information_DE.phone_de}</p>
      <br></br>
      <strong>Informations référent(e) Vis ma vie</strong>
      <p>
        NOM du ou de la référent(e) : ${etablissementInfo.information_referent?.nom_referent ||
    "Non renseigné"
    }
      </p>
      <p>
        Prénom du ou de la référent(e) : ${etablissementInfo.information_referent?.prenom_referent ||
    "Non renseigné"
    }
      </p>
      <p>
        Email : ${etablissementInfo.information_referent?.email_referent ||
    "Non renseigné"
    }
      </p>
      <p>
        Téléphone : ${etablissementInfo.information_referent?.phone_referent ||
    "Non renseigné"
    }
      </p>
      <br></br>
      <p>
        Pour visualiser les possibilités d'accueils que vous avez renseigné pour
        votre établissement, veuillez cliquer au lien suivant :
        ${websiteUrl}
      </p>
      <br></br>
      <p>Bien cordialement</p>
      <p>L'équipe organisatrice</p>
      <br></br>
      <img
      src="${emailSignatureBASE64}"
      alt="signature_logo"
      width={100}
      height={100}
      ></img>
    </article>`;
}

export function generateInscriptionModificationEmail(
  prenom: string,
  nom: string,
  etablissementInfo: Etablissement
) {
  return `<article>
      <p>
        Bonjour ${prenom} ${nom},
      </p>

      <p>
        Vous venez d'apporter des modifications à votre inscription sur le
        portail “Vis ma vie”.
      </p>

      <p>Vous trouverez ci-dessous le récapitulatif.</p>

      <p>
        Si cela s'avère nécessaire, vous pouvez modifier encore les possibilités
        d'accueil en vous connectant à nouveau sur la plateforme. La date limite
        pour le faire est le ${process.env.REACT_APP_DATE_END_INSCRIPTION_LITERAL}. Au-delà, merci de contacter directement
        le collaborateur qui ne pourrait plus être accueilli dans votre
        établissement.
      </p>

      <p>Bien à vous.</p>

      <p>L'équipe projet “Vis ma vie”</p>

      <b>Récapitulatif de vos modifications d'inscription :</b>
      <br></br>
      <strong>Informations établissement</strong>
      <p>Type d'établissement : ${etablissementInfo.type}</p>
      <p>Nom de l'établissement : ${etablissementInfo.name}</p>
      <p>
        Adresse de l'établissement : ${etablissementInfo.localisation.address}
      </p>
      <p>
        Accès établissement et transports : ${etablissementInfo.information_access
    }
      </p>
      <p>
        Région opérationnelle de rattachement de l'établissement : ${etablissementInfo.information_regional.region
    }
      </p>
      <p>
        NOM du ou de la DR : ${etablissementInfo.information_regional.nom_dr}
      </p>
      <p>
        Prénom du ou de la DR : ${etablissementInfo.information_regional.prenom_dr
    }
      </p>
      <br></br>
      <strong>Informations Directeur(trice) d'établissement</strong>
      <p>NOM du ou de la DE : ${etablissementInfo.information_DE.nom_de}</p>
      <p>
        Prénom du ou de la DE : ${etablissementInfo.information_DE.prenom_de}
      </p>
      <p>Email : ${etablissementInfo.information_DE.email_de}</p>
      <p>Téléphone : ${etablissementInfo.information_DE.phone_de}</p>
      <br></br>
      <strong>Informations référent(e) Vis ma vie</strong>
      <p>
        NOM du ou de la référent(e) : ${etablissementInfo.information_referent?.nom_referent ||
    "Non renseigné"
    }
      </p>
      <p>
        Prénom du ou de la référent(e) : ${etablissementInfo.information_referent?.prenom_referent ||
    "Non renseigné"
    }
      </p>
      <p>
        Email : ${etablissementInfo.information_referent?.email_referent ||
    "Non renseigné"
    }
      </p>
      <p>
        Téléphone : ${etablissementInfo.information_referent?.phone_referent ||
    "Non renseigné"
    }
      </p>
      <br></br>
      <p>
        Pour visualiser les possibilités d'accueils que vous avez renseigné pour
        votre établissement, veuillez cliquer au lien suivant :
        ${websiteUrl}
      </p>

      <br></br>
      <img
      src="${emailSignatureBASE64}"
      alt="signature_logo"
      width={100}
      height={100}
      ></img>
    </article>`;
}

export function generateCancelInscriptionEmail(prenom: string, nom: string) {
  return `
    <article>
      <p>
        Bonjour ${prenom} ${nom},
      </p>
      <p>
        Vous venez d'annuler votre inscription sur le portail “Vis ma vie”.
      </p>
      <p>
        Si toutefois, vous souhaitez proposer à nouveau des créneaux ou s'il
        s'agissait d'une erreur, la date limite pour s'enregistrer est le
        ${process.env.REACT_APP_DATE_END_INSCRIPTION_LITERAL}.
      </p>
      <br></br>
      <p>Bien à vous.</p>
      <p>L'équipe projet “Vis ma vie”</p>
      <br></br>
      <img
      src="${emailSignatureBASE64}"
      alt="signature_logo"
      width={100}
      height={100}
      ></img>
    </article>`;
}
