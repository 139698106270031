import {
  Configuration,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

const clientId = process.env.REACT_APP_LOGIN_CLIENTIND as string;
const redirectUri = process.env.REACT_APP_LOGIN_REDIRECT_URI as string;

const userFlow = process.env.REACT_APP_LOGIN_USERFLOW as string;
const authority = process.env.REACT_APP_LOGIN_AUTHORITY as string;
const authorityDomain = process.env.REACT_APP_LOGIN_AUTHORITY_DOMAIN as string;

// Apologies for not finding the corresponding type
// for the 'msalConfigB2CPolicies' object
export const msalConfigB2CPolicies = {
  names: {
    signUpSignIn: userFlow,
    forgotPassword: userFlow,
    editProfile: userFlow,
  },
  authorities: {
    signUpSignIn: {
      authority: `${authority}/${userFlow}`,
    },
    editProfile: {
      authority: `${authority}/${userFlow}`,
    },
    forgotPassword: {
      authority: `${authority}/${userFlow}`,
      scopes: [],
    },
  },
  authorityDomain: authorityDomain,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: msalConfigB2CPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [msalConfigB2CPolicies.authorityDomain],
    redirectUri: redirectUri,
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["user.read", "mail.send"],
};

export const msalInstance = new PublicClientApplication(msalConfig);
