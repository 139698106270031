import {
  EtablissementValidationType,
  IEtablissement,
  Localisation,
  Metier,
} from "../../../schemas-and-types/etablissement/etablissemen.schema";

export function getInitialValuesOfEtablissement(
  etablissement: IEtablissement | undefined
) {
  return {
    name: etablissement?.name ?? "-",
    type: etablissement?.type ?? "-",
    information_access: etablissement?.information_access ?? "-",

    // InformationRegional :
    region: etablissement?.information_regional?.region ?? "-",
    nom_dr: etablissement?.information_regional?.nom_dr ?? "-",
    prenom_dr: etablissement?.information_regional?.prenom_dr ?? "-",

    // InformationDE
    nom_de: etablissement?.information_DE?.nom_de ?? "-",
    prenom_de: etablissement?.information_DE?.prenom_de ?? "-",
    email_de: etablissement?.information_DE?.email_de ?? "-",
    phone_de: etablissement?.information_DE?.phone_de ?? "-",

    // InformationReferent
    nom_referent: etablissement?.information_referent?.nom_referent,
    prenom_referent: etablissement?.information_referent?.prenom_referent,
    email_referent: etablissement?.information_referent?.email_referent,
    phone_referent: etablissement?.information_referent?.phone_referent,

    localisation: etablissement?.localisation ?? {
      address: "-",
      countrySecondarySubdivision: "-",
      region: "-",
      latitude: 0,
      longitude: 0,
    },
  };
}

