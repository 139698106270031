import { InferType, object, string } from "yup";

//* Types and schemas related to the form validation for add and update Etablissement
// The 'EtablissementValidationSchema' schema and 'EtablissementValidationType' would be used to validate
// forms using 'react-hook-form' and 'yup'

// THE NAMES OF THE VARIABLES IN THE SCHEMA VALIDATION OBJECT
// HAVE TO BE THE SAME AS IN THE BACKEND SCHEMA

// const frPhoneRegex = /(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/
// const frPhoneRegex = /^[1-9](\d{2}){4}$/
const frPhoneRegex = /^[1-9]\d{8,10}$/;
export const EtablissementValidationSchema = object({
  name: string().required("Ce champ est obligatoire"),
  type: string().required("Ce champ est obligatoire"), // this is a dropdown
  information_access: string().required("Ce champ est obligatoire"),
  address: string().required("Ce champ est obligatoire"),
  // InformationRegional :
  region: string().required("Ce champ est obligatoire"), // this is a dropdown
  nom_dr: string().required("Ce champ est obligatoire"),
  prenom_dr: string().required("Ce champ est obligatoire"),

  // InformationDE
  nom_de: string().required("Ce champ est obligatoire"),
  prenom_de: string().required("Ce champ est obligatoire"),
  email_de: string()
    .required("Ce champ est obligatoire")
    .email("L'email n'est pas valide"),
  phone_de: string()
    .matches(frPhoneRegex, "Entrez un numéro de téléphone valide")
    .required("Ce champ est obligatoire"),

  // InformationReferent
  nom_referent: string(),
  prenom_referent: string(),
  email_referent: string().email("L'email n'est pas valide"),
  phone_referent: string()
    .test({
      name: 'phone',
      test: (value) => {
        if (!value) {
          // Skip validation if value is empty or undefined
          return true;
        }
        // Run matches validation if value is not empty or undefined
        return frPhoneRegex.test(value);
      },
      message: "Entrez un numéro de téléphone valide",
    }),
});

export type EtablissementValidationType = InferType<
  typeof EtablissementValidationSchema
>;

//* Types and interfaces related to the actual Etablissement object in the backend
// We will use this 'Etablissement' type in the our axios requests for example
export interface Etablissement {
  // Etablissement type equivalent as much as possible to the one in the backend
  _id?: string;
  name: string;
  type: TypeEtablissement | string;
  information_access: string;
  localisation: Localisation;
  information_regional: InformationRegional;

  information_DE: InformationDE;
  information_referent?: InformationReferent;
  userOID: string;
  metiers?: MetierInBackend[];

  nombre_total_disponibilites?: number;
  nombre_disponibilites_restauration?: number;
  nombre_disponibilites_maintenance?: number;
  nombre_disponibilites_hotellerie_serveur?: number;
  nombre_disponibilites_hotellerie_ASH?: number;
  nombre_disponibilites_animation?: number;
  nombre_disponibilites_accueil_et_administration?: number;

  //* Pariticipations
  nombre_total_participations?: number;
  nombre_participations_restauration?: number;
  nombre_participations_maintenance?: number;
  nombre_participations_hotellerie_serveur?: number;
  nombre_participations_hotellerie_ASH?: number;
  nombre_participations_animation?: number;
  nombre_participations_accueil_et_administration?: number;
}
export type IEtablissement = Omit<Etablissement, "_id">; // Etablissement type without the id and foreign keys, used in the UI

export type Localisation = {
  address: string;
  region: string;
  countrySecondarySubdivision: string;
  latitude: number;
  longitude: number;
};

export const emptyLocalisation = {
  address: "",
  region: "",
  countrySecondarySubdivision: "",
  longitude: 0,
  latitude: 0,
};

export type InformationRegional = {
  region: string;
  nom_dr: string;
  prenom_dr: string;
};

export type InformationDE = {
  nom_de: string;
  prenom_de: string;
  email_de: string;
  phone_de: string;
};

export type InformationReferent = {
  nom_referent?: string;
  prenom_referent?: string;
  email_referent?: string;
  phone_referent?: string;
};

// Types concerning the metiers (Restauration for example) and their disponiblite dates checkboxs
// These two types also concerne the general Etablissement object in the backend
export type Metier = {
  title: string;
  job_title: string;
  premiere_semaine: Disponibilite[];
  deuxieme_semaine: Disponibilite[];
  troisieme_semaine: Disponibilite[];
  quatrieme_semaine: Disponibilite[];
  isDisponible: boolean;
};

export type MetierInBackend = {
  title: string;
  job_title: string;
  premiere_semaine: Disponibilite[] | null;
  deuxieme_semaine: Disponibilite[] | null;
  troisieme_semaine: Disponibilite[] | null;
  quatrieme_semaine: Disponibilite[] | null;
};
export type Disponibilite = {
  date: Date;
  nombre_professionnel_disponible: number;
};

export type TypeEtablissement =
  | "EHPAD"
  | "Clinique - Santé Mentale"
  | "Clinique - SSR";

// Here is an example :

// 'Indicateur	Code établissement	Valeur
// 'Consommation de Solution Hydro Alcoolique'	01	0
// 'Consommation de Solution Hydro Alcoolique'	02	0
// 'Consommation de Solution Hydro Alcoolique'	03	98.8
// 'Consommation de Solution Hydro Alcoolique'	04 50.5
// 'Consommation de Solution Hydro Alcoolique'	05	0
// 'Dernier résultat qualité'	05	C
// 'Dernier résultat qualité'	03	D
// 'Dernier résultat qualité'	01	B
// 'Dernier résultat qualité'	02	A
// 'Dernier résultat qualité'	04	B
// 'Evaluation de la douleur et prise en charge' 	05	-
// 'Evaluation de la douleur et prise en charge' 	01	-
// 'Evaluation de la douleur et prise en charge' 	02	98.6
// 'Evaluation de la douleur et prise en charge' 	03	68.6
// 'Evaluation de la douleur et prise en charge' 	04	91.4
// "Evaluation de l'autonomie"	03	87.7
// "Evaluation de l'autonomie"	02	98
// "Evaluation de l'autonomie"	05	59.7
// "Evaluation de l'autonomie"	01	-
// "Evaluation de l'autonomie"	04	66.7

// Here we have 5 'Etablissement' with the 'Code établissement's of 01,02,03,04,05.
// Could you please write a javascript script which would regroupe these properties inside their respective 'Etablissement' object, please ?

// Here are the properties which I want to have in each etablissement array alongside with their respective column name in the spread sheet :

// 'Consommation de Solution Hydro Alcoolique (%)' => consommation_solution_hydro_alcoolique
// 'Dernier résultat qualité' => dernier_resultat_qualite
// "Dernière date d'audit" => derniere_date_audit
// "Directives anticipées (%)" => directives_anticipees
// "Documents de sortie (%)" => documents_de_sortie
// "Evaluation de la douleur et prise en charge (%)" = > evaluation_douleur_et_prise_en_charge
// "Evaluation de l'autonomie (%)" => evaluation_autonomie
// "Evaluation du risque de chute (%)" => evaluation_risque_de_chute
// "Evaluation du risque d'escarre (%)" => evaluation_risque_escarre
// "Evaluation et prise en charge de la douleur (%)" => evaluation_et_prise_en_charge_de_la_douleur
// "HASV2014_date" => HASV2014_date
// "HASV2014_note" => HASV2014_note
// "Interventions pharmaceutiques, proposition lues par les médecins (%)" => interventions_pharmaceutiques_proposition_lues_par_les_medecins
// "ISO9001_date" => ISO9001_date
// "Justif ATB thérapie à 7j (%)" => justif_ATB_therapie_à_7j
// "Personne de confiance (%)" => personne_de_confiance
// "Pesée + taille (%)" => pesee_et_taille
// "Pesée dans les 7j, +15j (%)" => pesee_dans_les_7j_et_15j
// "Prescription à l'entrée (%)" => prescription_a_lentree
// "Projet de soin/projet de vie (%)" => projet_de_soin_projet_de_vie
// "Projet thérapeutique (%)" => projet_therapeutique
// "Qualité de la lettre de liaison (%)" => qualite_de_la_lettre_de_liaison
// "Réévaluation antibiothérapie à 48h (%)" => reevaluation_antibiotherapie__48h
// "Risque suicidaire (%)" => risque_suicidaire
// "Satisfaction de la disponibilité du personnel" => satisfaction_de_la_disponibilite_du_personnel
// "Satisfaction de la qualité de la prise en charge médicamenteuse (traitement, information…)" => satisfaction_de_la_qualite_de_la_prise_en_charge_medicamenteuse
// "Satisfaction des aides apportées pour les AVQ" => satisfaction_des_aides_apportees_pour_les_AVQ
// "Satisfaction des échanges avec l'équipe soignante" => satisfaction_des_echanges_avec_equipe_soignante
// "Satisfaction des soins dans leur ensemble" => satisfaction_des_soins_dans_leur_ensemble
// "Satisfaction du respect de la dignité et de l'intimité" => satisfaction_du_respect_de_la_dignite_et_de_intimite
// "Satisfaction sur l'item de la réponse médicale et humaine en cas de douleur" => satisfaction_sur_item_de_la_reponse_medicale_et_humaine_en_cas_de_douleur
// "Satisfaction sur la clarté des explications concernant l'état de santé" => satisfaction_sur_la_clarte_des_explications_concernant_etat_de_sante
// "Synthèse de staff pluridisciplinaire (%)" => synthese_de_staff_pluridisciplinaire
// "Tx de patient avec une évaluation de la douleur" => tx_de_patient_avec_une_evaluation_de_la_douleur
// "Variation de poids (%)" => variation_de_poids